<template>
  <div class="flex column items-center justify-center">
    <Mural
      class="q-mt-md"
      style="width: 97%"
      :breadcrumbs="breadcrumbs"
      route="new-content"
      :query="{
        courseId: course_id,
        trainingId: training_id,
        postId: post_id,
      }"
    >
    </Mural>
    <div class="self-center q-mt-xl full-height" style="width: 77vw">
      <TableDynamic :columns="columns" :list="row">
        <template v-slot:body="props">
          <div>
            <q-btn
              color="default-pink"
              icon="o_edit_note"
              title="Corrigir"
              :to="{
                name: 'correction',
                query: {
                  quizId: props.props.row.quiz_id,
                  userId: props.props.row.user_id,
                  score: props.props.row.score,
                  studentsName: props.props.row.students_name,
                  quizStudentId: props.props.row.quiz_students_id,
                  maxScore: props.props.row.max_score,
                  title: title,
                  postId: post_id,
                  trainingId: training_id,
                  courseId: course_id,
                },
              }"
              flat
              round
              dense
            />
          </div>
        </template>
      </TableDynamic>
    </div>
  </div>
</template>

<script>
/* Components */
import Mural from "@/components/Mural.vue";
import TableDynamic from "@/components/shared/tables/TableDynamic.vue";

/* Vue */
import { ref, onMounted } from "vue";

/* VUEROUTER */
import { useRoute, useRouter } from "vue-router";

/* Services */
import TrailDataServices from "@/services/TrailDataServices";

/* Utils */

import AlertsClass from "@/utils/Notifications/AlertsClass";

export default {
  name: "OpenQuestionCorrection",
  setup(props) {
    /* CONSTANTS */
    const route = new useRoute();
    const _TrailDataServices = new TrailDataServices();
    const columns = [
      {
        label: "Nome",
        name: "students_name",
        align: "center",
        field: (row) => row.students_name,
        sortable: true,
      },
      {
        label: "Nota",
        align: "center",
        name: "score",
        field: (row) => row.score,
        format: (row) => row.toFixed(2),
        sortable: true,
      },
      {
        label: "Status",
        align: "center",
        field: (row) => row.revised,
        format: (row) => (row ? "Corrigida" : "Não corrigida"),
      },
      { label: "Correção", align: "center", name: "acao", field: "correcao" },
    ];
    const row = ref([]);
    const Alerts = new AlertsClass();
    const router = new useRouter();

    /* VARIABLES */
    let quiz_id = route.query.quiz_id;
    let course_id = route.query.course_id;
    let training_id = route.query.training_id;
    let post_id = route.query.post_id;
    let title = route.query.title;
    let breadcrumbs = ref([`Correção - ${title}`]);

    async function _getAllQuiz() {
      _TrailDataServices
        .getAllQuiz(quiz_id)
        .then((response) => {
          response.data.forEach((element) => {
            row.value.push(element);
          });
        })
        .catch((error) => {
          if (error.response.data === "Não existem quizes pendentes.") {
            Alerts.alertOf(
              "Não existem questões para serem corrigidas nesse quiz. Por favor aguarde!"
            );
            setTimeout(() => {
              router.push({
                name: "new-content",
                query: {
                  courseId: course_id,
                  trainingId: training_id,
                  postId: post_id,
                },
              });
            }, 7000);
          }
        });
    }

    onMounted(() => {
      _getAllQuiz();
    });

    return {
      /* VARIABLES */
      quiz_id,
      breadcrumbs,
      post_id,
      training_id,
      course_id,
      title,

      /* Constants */
      columns,
      row,
    };
  },
  components: {
    Mural,
    TableDynamic,
  },
};
</script>

<style lang="scss" scoped>

</style>
